import { NgModule } from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {split, ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import {WebSocketLink} from '@apollo/client/link/ws';
import {getMainDefinition} from '@apollo/client/utilities';
import { HttpHeaders } from '@angular/common/http';

//PROD
//const uri = 'https://api.omnipolis.tech/v1/graphql'; // <-- add the URL of the GraphQL server here
//const wsUri = 'wss://api.omnipolis.tech/v1/graphql';

//DEV
const uri = 'https://dev-api.omnipolis.app/v1/graphql'; // <-- add the URL of the GraphQL server here
const wsUri = 'wss://dev-api.omnipolis.app/v1/graphql';

@NgModule({
    providers: [
      {
        provide: APOLLO_OPTIONS,
        useFactory(httpLink: HttpLink): ApolloClientOptions<any> {
          // Create an http lin
          const http = httpLink.create({
            uri: uri,
            headers: new HttpHeaders({
                //PROD SECRET
                //'x-hasura-admin-secret': `EkShvrma2Tndry4sp`
                
                //DEV SECRET
                'x-hasura-admin-secret': `opsecret123`
            })
          });
  
          // Create a WebSocket link:
          const ws = new WebSocketLink({
            uri: wsUri,
            options: {
              reconnect: true,
            },
          });
          
  
          // using the ability to split links, you can send data to each link
          // depending on what kind of operation is being sent
          const link = split(
            // split based on operation type
            ({query}) => {              
              let definition = getMainDefinition(query);
              return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            ws,
            http,
          );
  
          return {
            link,
            cache: new InMemoryCache({
                dataIdFromObject: (obj: any) => obj.id
            }),
            defaultOptions: {
              watchQuery: {
                errorPolicy: 'all'
              }
            }
          };
        },
        deps: [HttpLink],
      },
    ],
  })
export class GraphQLModule { }
