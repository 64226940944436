import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Subject, timer, merge } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
@Injectable()
export class SharedService {
    // Observable string sources
    private emitChangeSource = new Subject<any>();
    logout = new Subject<any>();

    
    constructor(private apollo: Apollo, private http: HttpClient) { }
    // Observable string streams
    changeEmitted$ = this.emitChangeSource.asObservable();
    // Service message commands
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    subscribeToEvents() {

        // const observable = merge(timer(0, 30000),this.refreshEvent$.asObservable);
    
        // return observable.map(() => this.getEventsData());
    
        return timer(0, 10000).pipe(
          takeUntil(this.logout),
          switchMap(() => this.getEventsData()
          )
        )
      }
    
      getEventsData () {
        var curr = new Date();
        curr.setHours(curr.getHours() - 24);
        const ACTIVE_EVENTS = gql`
        query getevents {
          events(distinct_on: id, where: {event_type: {_in: ["TEST", "active_emergency"]}, _and: {_not: {event_status: {_in: ["false_emergency", "Resolved"]}}, _and: {user: {user_type: {_eq: "Omnipolis Emergency Device User"}}}}, timestamp: {_gte: "${curr.toISOString()}"}}, order_by: {id: desc, timestamp: desc}) {
            event_status
            event_details
            event_type
            user {
              first_name
            }
            id
            attended_status
            attended_by
            timestamp
          }
        }
        `
        return this.apollo.watchQuery({
          query: ACTIVE_EVENTS,
          fetchPolicy: 'network-only'
        }).valueChanges;
      }
}