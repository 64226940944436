import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent { 
  loginForm = new FormGroup({
    email: new FormControl('',[Validators.required]),
    password: new FormControl('',[Validators.required]),
  });

  constructor(public router: Router, private apollo: Apollo) { 
    //this.errorMessage = '';
  }

  ngOnInit() {
    sessionStorage.clear();
    sessionStorage.setItem('agent','');
  }

  onSubmit(value){
    //this.errorMessage = '';
    console.log(value)

    if(this.loginForm.valid){
      const query = `
      {
        emergency_agent(where: {password: {_eq: "${value.password}"}, mail: {_eq: "${value.email}"}}) {
          id
          name
          role
          mail
        }
      }
      `
      this.apollo
        .watchQuery({
          query: gql(query),
        })
        .valueChanges.subscribe((result: any) => {
          console.log(result);
          if(result?.data?.emergency_agent[0]?.id){
            sessionStorage.setItem('isLoggedIn', 'true');
            sessionStorage.setItem('agentName', result?.data?.emergency_agent[0]?.name);
            sessionStorage.setItem('agentRole', result?.data?.emergency_agent[0]?.role);
            sessionStorage.setItem('email', result?.data?.emergency_agent[0]?.mail);
            sessionStorage.setItem('isCorporate', 'false');
            if(sessionStorage.getItem('agentRole') == '37') {
              sessionStorage.setItem('isCorporate','true');
            } else {
              sessionStorage.setItem('isCorporate', 'false');
            }         
            if(sessionStorage.getItem('agentRole') == '35') {
              this.router.navigate(['/pcrdashboard'])
            } else if(sessionStorage.getItem('isCorporate') == 'true') {
              sessionStorage.setItem('corporateTab', 'employees')
              this.router.navigate(['/corporate'])
            } else {
              this.router.navigate(['/dashboard'])
            }
          } else {
            //this.errorMessage = "Unable to login. Please check ur credentials";
            alert("Unable to login. Please check your credentials")
          }
        })//err => this.errorMessage = err.message)
    } else {
      //this.errorMessage = "Unable to login. Please check ur credentials";
      alert("Unable to login. Please check your credentials")
    }
    
  }

}
