import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'omnicityzen',
    redirectTo: 'omnicityzen',
    pathMatch: 'full',
  }, 
  {
    path: 'pcrdashboard',
    redirectTo: 'pcrdashboard',
    pathMatch: 'full'
  }, 
  {
    path: 'past-events',
    redirectTo: 'past-events',
    pathMatch: 'full'
  },  
  {
    path: 'reports',
    redirectTo: 'reports',
    pathMatch: 'full'
  },  
  {
    path: 'device-reports',
    redirectTo: 'device-reports',
    pathMatch: 'full'
  },
  {
    path: 'other-emergencies',
    redirectTo: 'other-emergencies',
    pathMatch: 'full'
  },
  {
    path: 'corporate',
    redirectTo: 'corporate',
    pathMatch: 'full'
  },
  {
    path: 'recent-events',
    redirectTo: 'recent-events',
    pathMatch: 'full'
  },
  {
    path: 'support',
    redirectTo: 'support',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'pcrdashboard',
        loadChildren: () => import('./views/hawkeye/hawkeye.module').then(m => m.HawkeyeModule)
      },
      {
        path: 'omnicityzen',
        loadChildren: () => import('./views/omnicityzen/omnicityzen.module').then(m => m.OmnicityzenModule)
      },
      {
        path: 'devices',
        loadChildren: () => import('./views/devices/devices.module').then(m => m.DevicesModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./views/clients/clients.module').then(m => m.ClientsModule)
      },
      {
        path: 'past-events',
        loadChildren: () => import('./views/pastevents/pastevents.module').then(m => m.PasteventsModule)
      },
      {
        path: 'corporate',
        loadChildren: () => import('./views/corporate/corporate.module').then(m => m.CorporateModule)
      },
      {
        path: 'reports',
        loadChildren: () => import('./views/reports/reports.module').then(m => m.ReportsModule)
      },
      {
        path: 'report/:id',
        loadChildren: () => import('./views/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'device-reports',
        loadChildren: () => import('./views/device-reports/device-reports.module').then(m => m.DeviceReportsModule)
      },
      {
        path: 'recent-events',
        loadChildren: () => import('./views/recentevents/recentevents.module').then(m => m.RecenteventsModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./views/support/support.module').then(m => m.SupportModule)
      },
      {
        path: 'other-emergencies',
        loadChildren: () => import('./views/otheremergencies/otheremergencies.module').then(m => m.OtherEmergenciesModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
