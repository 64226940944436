import { NgModule } from '@angular/core';
import { AppDatePipe } from './date.pipe';
import { ReversePipe } from './reverse.pipe';

@NgModule({
  imports: [],
  declarations: [ 
    AppDatePipe,
    ReversePipe
  ],
  exports: [
    AppDatePipe,
    ReversePipe
  ]
})
export class PipesModule {}