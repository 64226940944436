import {Component, OnInit} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { navItems } from '../../_nav';
import { SharedService } from '../../services/shared-service'
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ["./default-layout.component.scss"]
})
export class DefaultLayoutComponent {   
  passwordForm = new FormGroup({
    oldPassword: new FormControl('',[Validators.required]),
    newPassword: new FormControl('',[Validators.required]),
    confirmNewPassword: new FormControl('',[Validators.required]),
  });
  

  public sidebarMinimized = false;
  public navItems = navItems;
  public role: string;
  public roleName: string;
  public name: string;
  public isCorporate: string;
  public corporateTab: string;
  public showAmeyoModal: string = "none";
  activeEmergency: boolean = false;
  private subscriptions: Subscription[]
  audio = new Audio();

  constructor( private apollo: Apollo,
               private router: Router,
               private sharedService: SharedService,
               private toastrService: ToastrService) { 
    //this.errorMessage = '';
  }

  private playAudio() {
    this.audio.play();
  }

  private stopAudio() {
    this.audio.pause();
  }

  ngOnInit() {
    this.audio.src = "assets/audio/warning.ogg"
    this.audio.load();
    this.role = sessionStorage.getItem('agentRole'); 
    this.name = sessionStorage.getItem('agentName');
    this.isCorporate = sessionStorage.getItem('isCorporate');
    this.corporateTab = sessionStorage.getItem('corporateTab');
    if(this.role == '1') {
      this.roleName = 'Agent'
    } else if(this.role == '2') {
      this.roleName = 'Supervisor'
    } else if(this.role == '36') {
      this.roleName = 'Administrator'
    } else if(this.role == '35') {
      this.roleName = 'PCR Agent'
    }
    this.sharedService.subscribeToEvents().subscribe((response: any) => {
      console.log(response.data.events)

      if(response.data.events.length) {
        response.data.events.forEach(event => {
          this.toastrService.error(`${event.user.first_name}`, "New Device Emergency", {
            timeOut: 3000
          }).onTap.pipe(take(1)).subscribe(() => this.toastrClickedHandler())
          this.activeEmergency = true
          this.playAudio()
        });
      } else {
        this.activeEmergency = false;
        this.stopAudio()
      }
    }) 
  }

  toastrClickedHandler() {
    this.router.navigate(['devices'])
  }

  onSubmit(value){    
    console.log(value)

    if(this.passwordForm.valid){
      if(value.newPassword == value.confirmNewPassword){
        const query = `
        {
          emergency_agent(where: {password: {_eq: "${value.oldPassword}"}, name: {_eq: "${this.name}"}, role: {_eq: ${this.role}}}) {
            id
          }
        }
        `
        this.apollo
          .watchQuery({
            query: gql(query),
          })
          .valueChanges.subscribe((result: any) => {
            console.log(result);
            if(result?.data?.emergency_agent[0]?.id){
              const updatePassword = `
                mutation MyMutation {
                  update_emergency_agent(where: {password: {_eq: "${value.oldPassword}"}, name: {_eq: "${this.name}"}, role: {_eq: ${this.role}}},  _set: {password: "${value.newPassword}"}){
                    affected_rows
                  }
                }
                `                
              this.apollo.mutate({
                mutation: gql(updatePassword),
              }).subscribe((result: any) => {
                console.log(result)
                if(result?.data?.update_emergency_agent?.affected_rows == '1'){
                  alert("Updated Password")
                } else {
                  console.log(result);
                  alert("Something went wrong please try again")
                }
              });
            } else {
              alert("Please enter your current password properly")
            }
          });
      }
    }
  }

  searchItem(search: string) {
    this.sharedService.emitChange(search); 
  }

  redirectToUsers() {
    sessionStorage.setItem('reports', 'users')
    console.log(sessionStorage.getItem('reports'))
    this.router.navigate(['/reports']).then(page => { window.location.reload(); });;   
  }

  redirectToOmnicityzen() {
    sessionStorage.setItem('reports', 'omnicityzen')
    console.log(sessionStorage.getItem('reports'))
    this.router.navigate(['/reports']).then(page => { window.location.reload(); });;       
  }

  redirectToEvents() {
    sessionStorage.setItem('reports', 'events')
    console.log(sessionStorage.getItem('reports'))        
    this.router.navigate(['/reports']).then(page => { window.location.reload(); });;     
  }

  
  logout() {      
    this.sharedService.logout.next();
    document.getElementById("ameyoIframe").addEventListener("load", function() {
      window.frames["ameyo"].integration.doLogout(); 
    }, true)
    this.router.navigate(['/login']);   
  }

  refresh() {
    location.reload()
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  toggleAmeyoModal() {
    this.showAmeyoModal = this.showAmeyoModal == 'block' ? 'none' : 'block';
  }

}
